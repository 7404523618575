import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PanelIndexComponent } from './panel-index/panel-index.component';
import { PasswordPanelComponent } from './password-panel/password-panel.component';

const routes: Routes = [
  {
    path: '', component: PanelIndexComponent
  },
  {
    path: 'passwordPanel/:id', component: PasswordPanelComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
