import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.scss']
})
export class ColorSelectComponent implements OnInit {

  @Input() form: FormControl = new FormControl('#FFFFFF');
  @Input() placeholder!: string;

  constructor() { }

  ngOnInit() {
  }

}
