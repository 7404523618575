<div class="p-8 flex flex-column min-w-444">
  <div
    class="flex justify-between align-center gap-4 wrap-buttons-mobile w-full"
  >
    <app-concent-logo-svg class="w-full"></app-concent-logo-svg>
    <button
      *ngIf="tokenIsValid"
      class="btn btn-reverse"
      (click)="clearStorage()"
    >
      Validar outro token
    </button>
  </div>

  <div
    *ngIf="!tokenIsValid; else customs"
    class="box flex justify-center flex-column wrap-buttons-mobile"
  >
    <div *ngIf="!validateTokenMobile; else validate">
      <h1 class="title default-color-label">Login</h1>
      <div class="flex wrap-buttons-mobile justify-between gap-4">
        <div class="flex flex-column mt-32">
          <h2>Na Web</h2>
          <h3 class="mt-32">1 - Acessar <a class="font-bold" href="https://frontend.quaent.com.br/MyToken" target="_blank">frontend.quaent.com.br/MyToken</a> do seu celular ou computador, ou scaneando o qrcode ao lado</h3>
          <h3 class="mt-32">2 - Realizar o login</h3>
          <h4 class="mt-32">3 - Validar o código de acesso</h4>

        </div>
        <div>
          <app-qr-code-svg></app-qr-code-svg>
        </div>
      </div>
      <div class="flex mt-32 w-full justify-center">
        <button class="btn btn-reverse" (click)="validateTokenMobile = true">Validar código de acesso</button>
      </div>
    </div>
    <ng-template #validate>
      <div class="flex flex-column">
        <input
          class="code-panel"
          placeholder="Token"
          [formControl]="tokenValidate"
          (keydown.enter)="tokenValidate.value ? validToken(): false"
          required
        />
        <div class="flex justify-center w-full mt-32">
          <button
            class="btn btn-reverse"
            [ngClass]="tokenValidate.value ? '' : 'disabled'"
            (click)="validToken()"
            [disabled]="!tokenValidate.value"
          >
            Validar Token
          </button>
        </div>
      </div>
    </ng-template>
  </div>

  <ng-template #customs>
    <div class="box mt-32">
      <div class="flex">
        <input
          class="code-panel"
          placeholder="WebService"
          [formControl]="form.webService"
          required
        />
        <app-select-beep class="w-full ml-4" [form]="form.beepType"></app-select-beep>
      </div>

      <div
        class="w-full flex justify-between align-center gap-4 mt-32 wrap-mobile"
      >
        <div class="flex flex-column w-full">
          <app-color-select
            class="mt-32"
            [placeholder]="'Cor fundo Senha e Guichê principal:'"
            [form]="form.mainLabel"
          ></app-color-select>
          <app-color-select
            class="mt-32"
            [placeholder]="'Cor escrita Senha e Guichê principal:'"
            [form]="form.mainColor"
          ></app-color-select>
          <app-color-select
            class="mt-32"
            [placeholder]="'Cor fundo últimas chamadas:'"
            [form]="form.colorLastCalls"
          ></app-color-select>
          <app-color-select
            class="mt-32"
            [placeholder]="'Cor escrita últimas chamadas:'"
            [form]="form.labelLastCalls"
          ></app-color-select>

        </div>
        <div class="content-prototype">
          <app-visualize-panel
            [colors]="{
              webService: form.webService.value,
              mainColor: form.mainColor.value,
              mainLabel: form.mainLabel.value,
              colorLastCalls: form.colorLastCalls.value,
              labelLastCalls: form.labelLastCalls.value,
              panelPasswordBeep: form.beepType.value
            }"
          ></app-visualize-panel>
        </div>
      </div>
      <div class="flex w-full justify-center mt-32 wrap-buttons-mobile">
        <button class="btn btn-reverse" (click)="resetColors()">
          Resetar Cores para padrão
        </button>
        <button class="btn ml-4" (click)="postCustomPanel()">
          Salvar Alterações
        </button>
      </div>
    </div>

    <div class="box mt-32">
      <input
        type="text"
        class="code-panel"
        placeholder="Código de Painel"
        [formControl]="painelCod"
        required
      />
      <div class="w-full flex justify-center mt-32">
        <button
          class="btn"
          [ngClass]="painelCod.value && form.webService.value ? '' : 'disabled'"
          [disabled]="painelCod.value ? false : true"
          (click)="validChanges()"
        >
          Ir Para Painel
        </button>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <p style="color: #808080;">Cliente: {{ socialReason }}</p>
      <p style="color: #808080;"> Nome: {{ browserInfo.name }}, Versão: {{ browserInfo.fullVersion }}, Plataforma: {{ browserInfo.platform }}, speechSynthesis: {{ browserInfo.speechSynthesis }}, v1.2 </p>
    </div>
  </ng-template>
</div>
