<div class="w-full prototype" [ngStyle]="{'background-color': colors?.mainColor ?? '#ffffff'}">
  <div class="flex p-8 mt-32 justify-around">
    <div class="flex flex-column align-center">
      <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.mainLabel ?? '#000000'}">Senha</h3>
      <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.mainLabel ?? '#000000'}">0000</h3>
    </div>
    <div class="flex flex-column align-center">
      <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.mainLabel ?? '#000000'}">Guichê</h3>
      <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.mainLabel ?? '#000000'}">00</h3>
    </div>

  </div>
  <div class="p-2" [ngStyle]="{'background-color': colors?.colorLastCalls ?? 'FF6344'}">
    <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">Últimas Chamadas</h3>
    <div class="flex justify-around mt-32">
      <div class="flex flex-column align-center">
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">0000</h3>
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">Senha</h3>
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">00</h3>
      </div>
      <div class="w-2px" [ngStyle]="{'background-color': colors?.labelLastCalls ?? '#ffffff'}"></div>
      <div class="flex flex-column align-center">
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">0000</h3>
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">Senha</h3>
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">00</h3>
      </div>
      <div class="w-2px" [ngStyle]="{'background-color': colors?.labelLastCalls ?? '#ffffff'}"></div>
      <div class="flex flex-column align-center">
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">0000</h3>
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">Senha</h3>
        <h3 class="font-weigth-700" [ngStyle]="{'color': colors?.labelLastCalls ?? '#ffffff'}">00</h3>
      </div>

    </div>
  </div>
</div>
