import  token  from '../app/util/Token';
import { Guid } from 'src/app/util/guid';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { IHttpService } from './Interfaces/IHttpService';
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt';
import { headers } from './types/headers.model';
import { HeaderParams, QueryParams, RequestOptions } from './types/http.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export abstract class ChronosApiService implements IHttpService {

  public baseURL: string = '';
  public baseChronosUrl: string = 'https://homolog.chronos.concentsistemas.com.br/';
  protected headers: headers = {
    'Content-Type' : 'application/json'
  } as headers

  constructor(protected http: HttpClient, private router: Router) {
    const token = localStorage?.getItem('token');
    if(token) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);

      this.headers.Authorization = token;
      this.headers.tenantId = decodedToken.tid;
    }
  }

  get<T>(options?: RequestOptions & { withoutTenant?: boolean }) {
    const { _path, _headers } = this.getOptions(options);

    const url = `${this.baseChronosUrl}${_path}`;
    const requestOptions = { headers: _headers };

    return this.http.get<T>(url, requestOptions);
  }

  getAll<T>(options?: RequestOptions) {
    const { _path, _headers } = this.getOptions(options);

    const url = `${this.baseChronosUrl}${_path}`;
    const requestOptions = { headers: _headers };

    return this.http.get<T>(url, requestOptions);
  }

  post<T>(body: Object, path?: string, tenantId?: string) {
    const url = `${this.baseChronosUrl}${path}`;

    const options = { headers: this.headers };

    if (tenantId) this.headers.tenantId = tenantId;

    //@ts-ignore
    return this.http.post<T>(url, body, options);
  }

  put(body: Object, id: Guid, path?: string, tenantId?: string): Promise<Object> {
    const url = `${this.baseURL}${path}/${id}`;

    const options = { headers: this.headers };
    //@ts-ignore
    return this.http.put(url, body, options).toPromise();
  }

  delete(id: Guid, path?: string, tenantId?: string): Promise<Object> {
    let normalize
    if (id != null) {
      normalize = `${path}/${id}`;
    } else {
      normalize = `${path}`;
    }
    const url = `${this.baseURL}${normalize}`;

    const options = { headers: this.headers };
    //@ts-ignore
    return this.http.delete(url, options).toPromise();
  }

  deleteWithBody(id: Guid, body: Object, path?: string): Promise<Object> {
    // this.setHeaders();
    let normalize
    if (id != null) {
      normalize = `${path}/${id}`;
    } else {
      normalize = `${path}`;
    }
    const url = `${this.baseURL}${normalize}`;

    const options = { headers: this.headers, body :body };
    //@ts-ignore
    return this.http.delete(url, options).toPromise();
  }

  patch(body: Object, id: Guid, path?: string): Promise<Object> {
    // this.setHeaders();
    throw new Error('Method not test.');
  }

  refreshToken() {
    const url = `${this.baseURL}/profile/user/refresh`;

    return this.http.post(url, {
      accessToken: localStorage.getItem('token'),
      refreshToken: token.refreshToken,
      id: token.sub,
    });
  }

  private getOptions(options?: RequestOptions, tenantId?: string) {
    const _path: string = this.getPathWithQuery(options?.query, options?.path);
    let _headers = this.getHeaders(options?.headers);

    _headers = _headers.set('Content-Type', 'application/json');

    if (tenantId || _headers.has('tenantId')) {
      _headers = _headers.set('tenantId', tenantId || _headers.get('tenantId')!);
    } else {
      if (localStorage.getItem('TenantId')) {
        _headers = _headers.set('tenantId', localStorage.getItem('TenantId') as string);
      }
    }

    return { _path, _headers };
  }

  getPathWithQuery(params?: QueryParams, path = '') {
    if (path && !params) return path;
    if (!params) return '';

    const query: string[] = [];

    this.getKeyValues(params).forEach((paramValue, param) => {
      query.push(`${param}=${paramValue}`);
    });

    return path + ['?', ...query.join('&')].join('');
  }

  getKeyValues(params?: QueryParams | HeaderParams) {
    const values = new Map<string, any>();
    if (params) {
      Object.keys(params).forEach(param => {
        const paramValue = params[param];
        if ((typeof paramValue === 'string' && paramValue.length == 0) || paramValue === null) return;
        values.set(param, paramValue);
      });
    }
    return values;
  }

  getHeaders(params?: HeaderParams) {
    let headers = new HttpHeaders();
    this.getKeyValues(params).forEach((paramValue, param) => {
      headers = headers.set(param, paramValue);
    });
    return headers;
  }

}

