import { TokenService } from './../../services/token.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomPanelService } from 'src/services/custom-panel.service';
import { ColorRequest, ColorResponse, HttpMultiplesResponse, ValidTokenResponse } from '../../services/types/painel-index.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-panel-index',
  templateUrl: './panel-index.component.html',
  styleUrls: ['./panel-index.component.scss']
})
export class PanelIndexComponent implements OnInit {

  browserInfo!: {
    name: string,
    version: number,
    fullVersion: any,
    platform: any,
    vendor: any,
    appVersion: any,
    plugins: any,
    mimetypes: any,
    permissions: any,
    maxTouchPoints: any,
    speechSynthesis: any,
  }

  tokenIsValid: boolean = false;
  validateTokenMobile: boolean = false;
  firstValues!: ColorRequest;
  socialReason!: string;

  modelName: boolean = false;

  painelCod = new FormControl(null, [Validators.required]);
  tokenValidate = new FormControl(null, [Validators.required]);

  form = {
    webService: new FormControl(null, [Validators.required]),
    mainLabel: new FormControl('#000000'),
    mainColor: new FormControl('#FFFFFF'),
    labelLastCalls: new FormControl('#FFFFFF'),
    colorLastCalls: new FormControl('#FF6344'),
    beepType: new FormControl(1)
  }

  constructor(private router: Router, private tokenService: TokenService, private customPanelService: CustomPanelService) { }

  ngOnInit() {
    const token$ = this._verifyTokenStorage();
    this._getBrowserInformations();

    if (token$) {
      this.tokenIsValid = true;
      this._decodedTokenAndSetTenant(token$);
      this._getTokenInfos(token$);
    } else {
      this.clearStorage();
    }

  }

  private _decodedTokenAndSetTenant(token: string) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    localStorage.removeItem('tenantId');
    localStorage.setItem('tenantId', decodedToken.tid);

    return decodedToken;
  }

  private _verifyTokenStorage() {
    let tokenStorage = localStorage?.getItem('token');

    return tokenStorage ?? null;
  }

  clearStorage() {
    this.tokenIsValid = false;
    localStorage.removeItem('token');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('customsColors');
    localStorage.removeItem('wsPasswordPanel');
    localStorage.removeItem('wsPasswordPanelFault');
    this.tokenValidate.setValue(null);
  }

  validToken() {
    let obj = {
      token: this.tokenValidate.value
    }

    this.tokenService.post<ValidTokenResponse>(obj).subscribe({
      next: response => {
        localStorage.setItem('token', response.accessToken);
        this.validateTokenMobile = false;
        this.tokenIsValid = true;
        this._getTokenInfos(response.accessToken);
      },
      error: error => {
        alert(error.error.errors[0]);
      }
    })
  }

  private _getTokenInfos(token: string) {
    let decodedToken = this._decodedTokenAndSetTenant(token);

    this.customPanelService.getAll<HttpMultiplesResponse<ColorResponse>>({ headers: { Authorization: token, TenantId: decodedToken.tid } }).subscribe({
      next: response => {
        if (response.data.length > 0) {
          let infosColorResponse = response.data[0];

          if (infosColorResponse.webService) {
            localStorage.setItem("wsPasswordPanel", infosColorResponse.webService);
          }

          this.firstValues = {
            webService: infosColorResponse.webService,
            mainColor: infosColorResponse.mainColor,
            mainLabel: infosColorResponse.mainLabel,
            colorLastCalls: infosColorResponse.colorLastCalls,
            labelLastCalls: infosColorResponse.labelLastCalls,
            panelPasswordBeep: infosColorResponse.panelPasswordBeep
          }

          this.socialReason = infosColorResponse.socialReason;

          this.form.webService.setValue(infosColorResponse.webService);
          this.form.mainColor.setValue(infosColorResponse.mainColor);
          this.form.mainLabel.setValue(infosColorResponse.mainLabel);
          this.form.colorLastCalls.setValue(infosColorResponse.colorLastCalls);
          this.form.labelLastCalls.setValue(infosColorResponse.labelLastCalls);
          this.form.beepType.setValue(infosColorResponse.panelPasswordBeep);
        }
      },
      error: error => {
        alert('Houve um erro ao tentar pegar as informações de cores!');
      }
    })
  }

  postCustomPanel() {
    const obj = {
      webService: this.form.webService.value,
      mainColor: this.form.mainColor.value,
      mainLabel: this.form.mainLabel.value,
      colorLastCalls: this.form.colorLastCalls.value,
      labelLastCalls: this.form.labelLastCalls.value,
      panelPasswordBeep: parseInt(this.form.beepType.value)
    }

    const tenantId = localStorage.getItem("tenantId");

    this.customPanelService.post<ColorRequest>(obj, tenantId ?? undefined).subscribe({
      next: response => {
        alert("Alterações realizadas com sucesso!");
        localStorage.removeItem('wsPasswordPanel');
        localStorage.setItem("wsPasswordPanel", this.form.webService.value);

        this.firstValues = obj;
      },
      error: error => {
        alert(error.error.errors);
      }
    });
  }

  validChanges() {
    if (this.form.webService.value && this.painelCod.value) {
      let existingChanges = false;

      let obj: ColorRequest = {
        webService: this.form.webService.value,
        mainColor: this.form.mainColor.value,
        mainLabel: this.form.mainLabel.value,
        colorLastCalls: this.form.colorLastCalls.value,
        labelLastCalls: this.form.labelLastCalls.value,
        panelPasswordBeep: parseInt(this.form.beepType.value)
      }

      existingChanges = JSON.stringify(obj) === JSON.stringify(this.firstValues) ? false : true;

      if (!existingChanges) {
        this._setValuesStore()
      } else {
        alert("É necessário salvar as alterações ou resetar as cores!");
      }

    } else {
      alert("Informar WebService e código do Painel!");
    }
  }

  private _setValuesStore() {
    localStorage.setItem("wsPasswordPanel", this.form.webService.value);
    localStorage.setItem("customsColors", JSON.stringify(this.firstValues));
    this._navigateToPanel();
  }

  private _navigateToPanel() {
    this.router.navigateByUrl('passwordPanel/' + this.painelCod.value);
  }

  resetColors() {
    this.form.colorLastCalls.setValue('#FF6344')
    this.form.mainColor.setValue('#FFFFFF')
    this.form.labelLastCalls.setValue('#FFFFFF')
    this.form.mainLabel.setValue('#000000')
  }

  private _getBrowserInformations() {
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset = nAgt.indexOf("OPR")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
      // In MS Edge, the true version is after "Edg" in userAgent
    } else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
      browserName = "Microsoft Edge";
      fullVersion = nAgt.substring(verOffset + 4);
      // In MSIE, the true version is after "MSIE" in userAgent
    } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
      // In Chrome, the true version is after "Chrome"
    } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
      // In Safari, the true version is after "Safari" or after "Version"
    } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
      // In Firefox, the true version is after "Firefox"
    } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
      // In most other browsers, "name/version" is at the end of userAgent
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    if ((ix = fullVersion.indexOf(" ")) != -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    this.browserInfo = {
      name: browserName,
      version: majorVersion,
      fullVersion: fullVersion,
      platform: navigator.platform,
      vendor: navigator.vendor,
      appVersion: navigator.appVersion,
      plugins: navigator.plugins,
      mimetypes: navigator.mimeTypes,
      permissions: navigator.permissions,
      maxTouchPoints: navigator.maxTouchPoints,
      speechSynthesis: 'speechSynthesis' in window ?? null
    }
  }

}
