import { Injectable } from '@angular/core';
import { AuthApiService } from './auth-api.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordPanelService extends BaseService {

  constructor(HttpAuthService: AuthApiService){
    super(HttpAuthService, 'asenhaspainel');
  }

}
