import { MatDialog } from '@angular/material/dialog';
import { AudioService } from './../../services/audio.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordPanelService } from 'src/services/password-panel.service';
import { GetPasswordResponse } from '../../services/types/password-panel.model';
import { customs } from './password-panel.model';
import { AuthorizationModalComponent } from '../components/authorization-modal/authorization-modal.component';

@Component({
  selector: 'app-password-panel',
  templateUrl: './password-panel.component.html',
  styleUrls: ['./password-panel.component.scss']
})
export class PasswordPanelComponent implements OnInit, OnDestroy {

  passwords: Array<any> = [];
  panelCode: string = '';
  interval: any;
  requestFailure: number = 0;
  faultTolerance: number = 0;
  beep: number = 0;
  colorsPanel!: customs;

  constructor(private PasswordPanel: PasswordPanelService, private _dialog: MatDialog, private route: ActivatedRoute, private AudioService: AudioService, private router: Router) {
    this.panelCode = route.snapshot.params['id'];
    this.colorsPanel = JSON.parse(localStorage.getItem("customsColors")!)
    this.faultTolerance = Number(localStorage.getItem("wsPasswordPanelFault"));
    this.beep = this.colorsPanel.panelPasswordBeep;
  }

  async ngOnInit() {
    this.passwords = [];

    if (this.panelCode) {
      await this.get(this.panelCode);
      await this._playAudioNative(); // On open the page
      this.interval = setInterval(() => {
        let errors: string[] = this._verifyDatas();

        if (errors.length > 0) {
          alert(errors);
        } else {
          this.get(this.panelCode);
        }
      }, 10000)
    } else {
      alert('Código do Painel não encontrado!');
      this.router.navigate([".."]);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  private _openModal() {
    this._dialog.open(AuthorizationModalComponent)
  }

  async get(panelCode: string) {
    this.PasswordPanel.get<GetPasswordResponse[]>({ path: '?' + panelCode, alternativeBaseURL: true }).subscribe({
      next: async response => {
        setTimeout(async () => {
          if (response.length > 0) {
            this.passwords = response;
            if (response[0].GerAtendBeep === 'S') {

              if ('speechSynthesis' in window) {
                await this._playAudioNative();

                setTimeout(() => {
                  const msg = new SpeechSynthesisUtterance();
                  msg.text = `Senha ${response[0].GerAtendSenha} ${response[0].GerAtendGuiche}`;
                  msg.lang = 'pt-BR';
                  msg.rate = 0.7;

                  return speechSynthesis.speak(msg);
                }, 2000);
              } else {
                this._playAudioNative();
              }
            }
          }
        }, 2000);
      },
      error: error => {
        this.requestFailure++;
        if (this.requestFailure >= this.faultTolerance) {
          alert(`Aplicação tentou se comunicar ${this.requestFailure} vezes sem sucesso\n com ${error.url}`)
          clearInterval(this.interval);
          this.requestFailure = 0;
          // this.erro = error;
          // this.erro2 = `Aplicação tentou se comunicar ${this.requestFailure} vezes sem sucesso\n com ${error.url}`;
          // this.router.navigate([".."]);
        }
      }
    })
  }

  playAudio() {
    // this.AudioService.checkAccessAndPlayAudio(this.beep).catch(error => {
    //   this._openModal();
    // });
    this.AudioService.playAudio();
  }

  private async _playAudioNative() {
    document.getElementById('sound')?.click()
  }

  private _verifyDatas() {
    const errors: string[] = [];
    if (!localStorage.getItem("token")) {
      errors.push('Token não encontrado!');
    }

    if (!localStorage.getItem("tenantId")) {
      errors.push('Tenant não encontrado!');
    }

    return errors;
  }

}
