

import { Inject, Injectable } from '@angular/core';
import { Guid } from 'src/app/util/guid';
import { IBaseService } from './Interfaces/IBaseService';
import { IHttpService } from './Interfaces/IHttpService';
import { RequestOptions } from './types/http.model';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService implements IBaseService {

  protected path: string;
  protected httpService!: IHttpService;

  constructor(@Inject(String) httpService: IHttpService, @Inject(String) path: string) {
    this.path = path;
    this.httpService = httpService;
  }

  get<T>(options?: RequestOptions) {
    return this.httpService.get<T>({ ...options, path: this.path + (options?.path || '') });
  }

  getAll<T>(options?: RequestOptions) {
    return this.httpService.get<T>({
      path: this.path + (options?.path || ''),
      headers: options?.headers,
    });
  }

  getById<T>(id: string) {
    return this.httpService.get<T>({ path: `${this.path}/${id}` });
  }

  post<T>(body: Object, tenantId?: string) {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.post<T>(body, `${this.path}`, tenantId);
  }

  put(body: Object, id: Guid): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.put(body, id, `${this.path}`);
  }

  delete(id?: Guid): Promise<Object> {
    return this.httpService.delete(id!, `${this.path}`);
  }

  patch(body: Object, id: Guid): Promise<Object> {
    if (!body) throw new Error('Objeto inválido');
    return this.httpService.put(body, id, `${this.path}`);
  }
}
