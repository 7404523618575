import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ChronosApiService } from './chronos-api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomPanelService extends BaseService {

  constructor(HttpChronosService: ChronosApiService){
    super(HttpChronosService, 'api/v1/panelpassword/color');
  }
}
