import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AudioService } from 'src/services/audio.service';

@Component({
  selector: 'app-select-beep',
  templateUrl: './select-beep.component.html',
  styleUrls: ['./select-beep.component.scss']
})
export class SelectBeepComponent {

  @Input() form!: FormControl;

  constructor(private AudioService: AudioService) { }

  audio() {
    this.AudioService.playAudio(parseInt(this.form.value));
  }

}
