import { Component, Input, OnInit } from '@angular/core';
import { ColorRequest } from 'src/services/types/painel-index.model';

@Component({
  selector: 'app-visualize-panel',
  templateUrl: './visualize-panel.component.html',
  styleUrls: ['./visualize-panel.component.scss']
})
export class VisualizePanelComponent implements OnInit {

  @Input() colors!: ColorRequest

  constructor() { }

  ngOnInit() {
  }

}
