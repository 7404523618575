<div class="content">
  <div (click)="playAudio()" id="sound"></div>
  <div class="w-full grid-1fr h-full" [ngStyle]="{'background-color': colorsPanel?.mainColor ?? '#FFFFFF'}">
    <div class="mt-3vw pd-lr-6vw">
      <div class="w-full flex justify-around">
        <div class="flex h-full flex-column align-center">
          <h1 class="font-8vw" [ngStyle]="{'color': colorsPanel?.mainLabel ?? '#000000'}">Senha</h1>
          <h1 class="font-8vw" [ngStyle]="{'color': colorsPanel?.mainLabel ?? '#FF6344'}">{{passwords[0]?.GerAtendSenha ?? '0000'}}</h1>
        </div>
        <div class="flex h-full flex-column align-center">
          <h1 class="font-8vw" [ngStyle]="{'color': colorsPanel?.mainLabel ?? '#000000'}">Guichê</h1>
          <h1 class="font-8vw" [ngStyle]="{'color': colorsPanel?.mainLabel ?? '#FF6344'}">{{passwords[0]?.GerAtendGuiche ?? '00'}}</h1>
        </div>
      </div>
    </div>
    <div class="h-full min-h-full pd-1vw-8vw" [ngStyle]="{'background-color': colorsPanel?.colorLastCalls ?? '#FF6344'}">
      <div>
        <h3 class="font-3vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">Últimas Chamadas</h3>
      </div>
      <div class="flex justify-around mt-1vw">
        <div class="flex flex-column align-center">
          <h2 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">{{passwords[1]?.GerAtendSenha ?? '0000'}}</h2>
          <div class="flex flex-column align-center">
            <h3 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">Guichê:</h3>
            <h2 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">{{passwords[1]?.GerAtendGuiche ?? '00'}}</h2>
          </div>
        </div>
        <div class="b-6-white" [ngStyle]="{'background-color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}"></div>
        <div class="flex flex-column align-center">
          <h2 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">{{passwords[2]?.GerAtendSenha ?? '0000'}}</h2>
          <div class="flex flex-column align-center">
            <h3 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">Guichê:</h3>
            <h2 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">{{passwords[2]?.GerAtendGuiche ?? '00'}}</h2>
          </div>
        </div>
        <div class="b-6-white" [ngStyle]="{'background-color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}"></div>
        <div class="flex flex-column align-center">
          <h2 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">{{passwords[3]?.GerAtendSenha ?? '0000'}}</h2>
          <div class="flex flex-column align-center">
            <h3 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">Guichê:</h3>
            <h2 class="font-5vw text-white" [ngStyle]="{'color': colorsPanel?.labelLastCalls ?? '#FFFFFF'}">{{passwords[3]?.GerAtendGuiche ?? '00'}}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
