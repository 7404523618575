import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private _audioIsEnabled = false

  playAudio(audioNumber?: number) {
    return this._callAudio(audioNumber)
  }

  // checkAccessAndPlayAudio(audioNumber?: number) {
  //   if (this._audioIsEnabled) {
  //     return this._callAudio(audioNumber)
  //   }

  //   return navigator.mediaDevices.getUserMedia({ audio: true })
  //     .then(() => {
  //       this._getContext()
  //       this._callAudio(audioNumber)
  //     })
  // }

  private _callAudio(audioNumber?: number) {
    const audio = new Audio();
    const audio1 = './assets/sounds/painelbeep1.wav';
    const audio2 = './assets/sounds/painelbeep2.mp3';

    if (window.location.hostname !== "localhost") {
      audio.src = audioNumber === 1 ? audio2 : audio1;
    } else {
      audio.src = audioNumber === 1 ? audio2 : audio1;
    }

    audio.load();
    return audio.play();
  }

  private _getContext() {
    const context = new window.AudioContext()

    context.resume().then(() => {
      this._audioIsEnabled = true
    })
  }

}
