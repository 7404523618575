<div class="flex align-center gap-4">

  <select [formControl]="form" class="code-panel">
    <option value="0">Beep 1</option>
    <option value="1" selected>Beep 2</option>
  </select>

  <svg (click)="audio()" id="sound" class="cursor-pointer" width="30" height="30" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_74_17)">
      <mask id="mask0_74_17" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="51" height="51">
        <path d="M50.6782 0.487915H0.678223V50.4879H50.6782V0.487915Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_74_17)">
        <path d="M6.92822 33.8213V17.1546H13.1782L23.5949 8.82126V42.1546L13.1782 33.8213H6.92822Z" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M27.7615 19.2379C27.7615 19.2379 31.9281 20.2796 31.9281 25.4879C31.9281 30.6962 27.7615 31.7379 27.7615 31.7379" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.9282 15.0713C31.9282 15.0713 38.1782 16.8074 38.1782 25.4879C38.1782 34.1686 31.9282 35.9046 31.9282 35.9046" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.095 10.9046C36.095 10.9046 44.4283 13.3351 44.4283 25.4879C44.4283 37.6406 36.095 40.0712 36.095 40.0712" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_74_17">
        <rect width="50" height="50" fill="white" transform="translate(0.678223 0.487915)"/>
      </clipPath>
    </defs>
  </svg>

</div>
