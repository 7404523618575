import { Injectable } from '@angular/core';
import { AuthApiService } from './auth-api.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService extends BaseService {

  constructor(HttpAuthService: AuthApiService){
    super(HttpAuthService, 'api/v1/public/user/validatetoken');
  }
}
