<svg
  class="responsive"
  viewBox="0 0 230 230"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_66_15)">
    <path d="M110.4 0H101.2V9.2H110.4V0Z" fill="black" />
    <path d="M128.8 0H119.6V9.2H128.8V0Z" fill="black" />
    <path d="M156.4 0H147.2V9.2H156.4V0Z" fill="black" />
    <path d="M82.8 9.19995H73.6V18.4H82.8V9.19995Z" fill="black" />
    <path d="M101.2 9.19995H92V18.4H101.2V9.19995Z" fill="black" />
    <path d="M128.8 9.19995H119.6V18.4H128.8V9.19995Z" fill="black" />
    <path d="M138 9.19995H128.8V18.4H138V9.19995Z" fill="black" />
    <path d="M147.2 9.19995H138V18.4H147.2V9.19995Z" fill="black" />
    <path d="M156.4 9.19995H147.2V18.4H156.4V9.19995Z" fill="black" />
    <path d="M92 18.3999H82.8V27.5999H92V18.3999Z" fill="black" />
    <path d="M138 18.3999H128.8V27.5999H138V18.3999Z" fill="black" />
    <path d="M82.8 27.6001H73.6V36.8001H82.8V27.6001Z" fill="black" />
    <path d="M92 27.6001H82.8V36.8001H92V27.6001Z" fill="black" />
    <path d="M119.6 27.6001H110.4V36.8001H119.6V27.6001Z" fill="black" />
    <path d="M128.8 27.6001H119.6V36.8001H128.8V27.6001Z" fill="black" />
    <path d="M138 27.6001H128.8V36.8001H138V27.6001Z" fill="black" />
    <path d="M147.2 27.6001H138V36.8001H147.2V27.6001Z" fill="black" />
    <path d="M110.4 36.8H101.2V46H110.4V36.8Z" fill="black" />
    <path d="M128.8 36.8H119.6V46H128.8V36.8Z" fill="black" />
    <path d="M156.4 36.8H147.2V46H156.4V36.8Z" fill="black" />
    <path d="M82.8 46H73.6V55.2H82.8V46Z" fill="black" />
    <path d="M101.2 46H92V55.2H101.2V46Z" fill="black" />
    <path d="M138 46H128.8V55.2H138V46Z" fill="black" />
    <path d="M147.2 46H138V55.2H147.2V46Z" fill="black" />
    <path d="M82.8 55.2H73.6V64.4H82.8V55.2Z" fill="black" />
    <path d="M101.2 55.2H92V64.4H101.2V55.2Z" fill="black" />
    <path d="M119.6 55.2H110.4V64.4H119.6V55.2Z" fill="black" />
    <path d="M138 55.2H128.8V64.4H138V55.2Z" fill="black" />
    <path d="M156.4 55.2H147.2V64.4H156.4V55.2Z" fill="black" />
    <path d="M110.4 64.3999H101.2V73.5999H110.4V64.3999Z" fill="black" />
    <path d="M119.6 64.3999H110.4V73.5999H119.6V64.3999Z" fill="black" />
    <path d="M138 64.3999H128.8V73.5999H138V64.3999Z" fill="black" />
    <path d="M156.4 64.3999H147.2V73.5999H156.4V64.3999Z" fill="black" />
    <path d="M9.2 73.6001H0V82.8001H9.2V73.6001Z" fill="black" />
    <path d="M18.4 73.6001H9.20001V82.8001H18.4V73.6001Z" fill="black" />
    <path d="M27.6 73.6001H18.4V82.8001H27.6V73.6001Z" fill="black" />
    <path d="M36.8 73.6001H27.6V82.8001H36.8V73.6001Z" fill="black" />
    <path d="M46 73.6001H36.8V82.8001H46V73.6001Z" fill="black" />
    <path d="M64.4 73.6001H55.2V82.8001H64.4V73.6001Z" fill="black" />
    <path d="M73.6 73.6001H64.4V82.8001H73.6V73.6001Z" fill="black" />
    <path d="M82.8 73.6001H73.6V82.8001H82.8V73.6001Z" fill="black" />
    <path d="M92 73.6001H82.8V82.8001H92V73.6001Z" fill="black" />
    <path d="M101.2 73.6001H92V82.8001H101.2V73.6001Z" fill="black" />
    <path d="M110.4 73.6001H101.2V82.8001H110.4V73.6001Z" fill="black" />
    <path d="M119.6 73.6001H110.4V82.8001H119.6V73.6001Z" fill="black" />
    <path d="M128.8 73.6001H119.6V82.8001H128.8V73.6001Z" fill="black" />
    <path d="M165.6 73.6001H156.4V82.8001H165.6V73.6001Z" fill="black" />
    <path d="M184 73.6001H174.8V82.8001H184V73.6001Z" fill="black" />
    <path d="M202.4 73.6001H193.2V82.8001H202.4V73.6001Z" fill="black" />
    <path d="M220.8 73.6001H211.6V82.8001H220.8V73.6001Z" fill="black" />
    <path d="M9.2 82.8H0V92H9.2V82.8Z" fill="black" />
    <path d="M36.8 82.8H27.6V92H36.8V82.8Z" fill="black" />
    <path d="M46 82.8H36.8V92H46V82.8Z" fill="black" />
    <path d="M73.6 82.8H64.4V92H73.6V82.8Z" fill="black" />
    <path d="M82.8 82.8H73.6V92H82.8V82.8Z" fill="black" />
    <path d="M92 82.8H82.8V92H92V82.8Z" fill="black" />
    <path d="M110.4 82.8H101.2V92H110.4V82.8Z" fill="black" />
    <path d="M128.8 82.8H119.6V92H128.8V82.8Z" fill="black" />
    <path d="M165.6 82.8H156.4V92H165.6V82.8Z" fill="black" />
    <path d="M184 82.8H174.8V92H184V82.8Z" fill="black" />
    <path d="M211.6 82.8H202.4V92H211.6V82.8Z" fill="black" />
    <path d="M9.2 92H0V101.2H9.2V92Z" fill="black" />
    <path d="M18.4 92H9.20001V101.2H18.4V92Z" fill="black" />
    <path d="M36.8 92H27.6V101.2H36.8V92Z" fill="black" />
    <path d="M46 92H36.8V101.2H46V92Z" fill="black" />
    <path d="M64.4 92H55.2V101.2H64.4V92Z" fill="black" />
    <path d="M82.8 92H73.6V101.2H82.8V92Z" fill="black" />
    <path d="M92 92H82.8V101.2H92V92Z" fill="black" />
    <path d="M128.8 92H119.6V101.2H128.8V92Z" fill="black" />
    <path d="M138 92H128.8V101.2H138V92Z" fill="black" />
    <path d="M147.2 92H138V101.2H147.2V92Z" fill="black" />
    <path d="M174.8 92H165.6V101.2H174.8V92Z" fill="black" />
    <path d="M193.2 92H184V101.2H193.2V92Z" fill="black" />
    <path d="M211.6 92H202.4V101.2H211.6V92Z" fill="black" />
    <path d="M220.8 92H211.6V101.2H220.8V92Z" fill="black" />
    <path d="M230 92H220.8V101.2H230V92Z" fill="black" />
    <path d="M18.4 101.2H9.20001V110.4H18.4V101.2Z" fill="black" />
    <path d="M27.6 101.2H18.4V110.4H27.6V101.2Z" fill="black" />
    <path d="M73.6 101.2H64.4V110.4H73.6V101.2Z" fill="black" />
    <path d="M82.8 101.2H73.6V110.4H82.8V101.2Z" fill="black" />
    <path d="M92 101.2H82.8V110.4H92V101.2Z" fill="black" />
    <path d="M101.2 101.2H92V110.4H101.2V101.2Z" fill="black" />
    <path d="M110.4 101.2H101.2V110.4H110.4V101.2Z" fill="black" />
    <path d="M147.2 101.2H138V110.4H147.2V101.2Z" fill="black" />
    <path d="M156.4 101.2H147.2V110.4H156.4V101.2Z" fill="black" />
    <path d="M165.6 101.2H156.4V110.4H165.6V101.2Z" fill="black" />
    <path d="M174.8 101.2H165.6V110.4H174.8V101.2Z" fill="black" />
    <path d="M184 101.2H174.8V110.4H184V101.2Z" fill="black" />
    <path d="M193.2 101.2H184V110.4H193.2V101.2Z" fill="black" />
    <path d="M9.2 110.4H0V119.6H9.2V110.4Z" fill="black" />
    <path d="M27.6 110.4H18.4V119.6H27.6V110.4Z" fill="black" />
    <path d="M36.8 110.4H27.6V119.6H36.8V110.4Z" fill="black" />
    <path d="M55.2 110.4H46V119.6H55.2V110.4Z" fill="black" />
    <path d="M64.4 110.4H55.2V119.6H64.4V110.4Z" fill="black" />
    <path d="M82.8 110.4H73.6V119.6H82.8V110.4Z" fill="black" />
    <path d="M92 110.4H82.8V119.6H92V110.4Z" fill="black" />
    <path d="M110.4 110.4H101.2V119.6H110.4V110.4Z" fill="black" />
    <path d="M119.6 110.4H110.4V119.6H119.6V110.4Z" fill="black" />
    <path d="M128.8 110.4H119.6V119.6H128.8V110.4Z" fill="black" />
    <path d="M138 110.4H128.8V119.6H138V110.4Z" fill="black" />
    <path d="M147.2 110.4H138V119.6H147.2V110.4Z" fill="black" />
    <path d="M156.4 110.4H147.2V119.6H156.4V110.4Z" fill="black" />
    <path d="M165.6 110.4H156.4V119.6H165.6V110.4Z" fill="black" />
    <path d="M174.8 110.4H165.6V119.6H174.8V110.4Z" fill="black" />
    <path d="M193.2 110.4H184V119.6H193.2V110.4Z" fill="black" />
    <path d="M202.4 110.4H193.2V119.6H202.4V110.4Z" fill="black" />
    <path d="M211.6 110.4H202.4V119.6H211.6V110.4Z" fill="black" />
    <path d="M230 110.4H220.8V119.6H230V110.4Z" fill="black" />
    <path d="M9.2 119.6H0V128.8H9.2V119.6Z" fill="black" />
    <path d="M18.4 119.6H9.20001V128.8H18.4V119.6Z" fill="black" />
    <path d="M46 119.6H36.8V128.8H46V119.6Z" fill="black" />
    <path d="M55.2 119.6H46V128.8H55.2V119.6Z" fill="black" />
    <path d="M73.6 119.6H64.4V128.8H73.6V119.6Z" fill="black" />
    <path d="M92 119.6H82.8V128.8H92V119.6Z" fill="black" />
    <path d="M101.2 119.6H92V128.8H101.2V119.6Z" fill="black" />
    <path d="M110.4 119.6H101.2V128.8H110.4V119.6Z" fill="black" />
    <path d="M156.4 119.6H147.2V128.8H156.4V119.6Z" fill="black" />
    <path d="M165.6 119.6H156.4V128.8H165.6V119.6Z" fill="black" />
    <path d="M211.6 119.6H202.4V128.8H211.6V119.6Z" fill="black" />
    <path d="M220.8 119.6H211.6V128.8H220.8V119.6Z" fill="black" />
    <path d="M9.2 128.8H0V138H9.2V128.8Z" fill="black" />
    <path d="M27.6 128.8H18.4V138H27.6V128.8Z" fill="black" />
    <path d="M36.8 128.8H27.6V138H36.8V128.8Z" fill="black" />
    <path d="M46 128.8H36.8V138H46V128.8Z" fill="black" />
    <path d="M55.2 128.8H46V138H55.2V128.8Z" fill="black" />
    <path d="M64.4 128.8H55.2V138H64.4V128.8Z" fill="black" />
    <path d="M73.6 128.8H64.4V138H73.6V128.8Z" fill="black" />
    <path d="M82.8 128.8H73.6V138H82.8V128.8Z" fill="black" />
    <path d="M101.2 128.8H92V138H101.2V128.8Z" fill="black" />
    <path d="M119.6 128.8H110.4V138H119.6V128.8Z" fill="black" />
    <path d="M128.8 128.8H119.6V138H128.8V128.8Z" fill="black" />
    <path d="M147.2 128.8H138V138H147.2V128.8Z" fill="black" />
    <path d="M165.6 128.8H156.4V138H165.6V128.8Z" fill="black" />
    <path d="M174.8 128.8H165.6V138H174.8V128.8Z" fill="black" />
    <path d="M202.4 128.8H193.2V138H202.4V128.8Z" fill="black" />
    <path d="M211.6 128.8H202.4V138H211.6V128.8Z" fill="black" />
    <path d="M220.8 128.8H211.6V138H220.8V128.8Z" fill="black" />
    <path d="M230 128.8H220.8V138H230V128.8Z" fill="black" />
    <path d="M9.2 138H0V147.2H9.2V138Z" fill="black" />
    <path d="M46 138H36.8V147.2H46V138Z" fill="black" />
    <path d="M101.2 138H92V147.2H101.2V138Z" fill="black" />
    <path d="M156.4 138H147.2V147.2H156.4V138Z" fill="black" />
    <path d="M165.6 138H156.4V147.2H165.6V138Z" fill="black" />
    <path d="M174.8 138H165.6V147.2H174.8V138Z" fill="black" />
    <path d="M184 138H174.8V147.2H184V138Z" fill="black" />
    <path d="M9.2 147.2H0V156.4H9.2V147.2Z" fill="black" />
    <path d="M27.6 147.2H18.4V156.4H27.6V147.2Z" fill="black" />
    <path d="M46 147.2H36.8V156.4H46V147.2Z" fill="black" />
    <path d="M64.4 147.2H55.2V156.4H64.4V147.2Z" fill="black" />
    <path d="M73.6 147.2H64.4V156.4H73.6V147.2Z" fill="black" />
    <path d="M82.8 147.2H73.6V156.4H82.8V147.2Z" fill="black" />
    <path d="M92 147.2H82.8V156.4H92V147.2Z" fill="black" />
    <path d="M128.8 147.2H119.6V156.4H128.8V147.2Z" fill="black" />
    <path d="M138 147.2H128.8V156.4H138V147.2Z" fill="black" />
    <path d="M156.4 147.2H147.2V156.4H156.4V147.2Z" fill="black" />
    <path d="M165.6 147.2H156.4V156.4H165.6V147.2Z" fill="black" />
    <path d="M174.8 147.2H165.6V156.4H174.8V147.2Z" fill="black" />
    <path d="M184 147.2H174.8V156.4H184V147.2Z" fill="black" />
    <path d="M193.2 147.2H184V156.4H193.2V147.2Z" fill="black" />
    <path d="M211.6 147.2H202.4V156.4H211.6V147.2Z" fill="black" />
    <path d="M220.8 147.2H211.6V156.4H220.8V147.2Z" fill="black" />
    <path d="M230 147.2H220.8V156.4H230V147.2Z" fill="black" />
    <path d="M82.8 156.4H73.6V165.6H82.8V156.4Z" fill="black" />
    <path d="M92 156.4H82.8V165.6H92V156.4Z" fill="black" />
    <path d="M138 156.4H128.8V165.6H138V156.4Z" fill="black" />
    <path d="M147.2 156.4H138V165.6H147.2V156.4Z" fill="black" />
    <path d="M156.4 156.4H147.2V165.6H156.4V156.4Z" fill="black" />
    <path d="M193.2 156.4H184V165.6H193.2V156.4Z" fill="black" />
    <path d="M82.8 165.6H73.6V174.8H82.8V165.6Z" fill="black" />
    <path d="M92 165.6H82.8V174.8H92V165.6Z" fill="black" />
    <path d="M101.2 165.6H92V174.8H101.2V165.6Z" fill="black" />
    <path d="M128.8 165.6H119.6V174.8H128.8V165.6Z" fill="black" />
    <path d="M156.4 165.6H147.2V174.8H156.4V165.6Z" fill="black" />
    <path d="M174.8 165.6H165.6V174.8H174.8V165.6Z" fill="black" />
    <path d="M193.2 165.6H184V174.8H193.2V165.6Z" fill="black" />
    <path d="M202.4 165.6H193.2V174.8H202.4V165.6Z" fill="black" />
    <path d="M220.8 165.6H211.6V174.8H220.8V165.6Z" fill="black" />
    <path d="M230 165.6H220.8V174.8H230V165.6Z" fill="black" />
    <path d="M119.6 174.8H110.4V184H119.6V174.8Z" fill="black" />
    <path d="M156.4 174.8H147.2V184H156.4V174.8Z" fill="black" />
    <path d="M193.2 174.8H184V184H193.2V174.8Z" fill="black" />
    <path d="M82.8 184H73.6V193.2H82.8V184Z" fill="black" />
    <path d="M110.4 184H101.2V193.2H110.4V184Z" fill="black" />
    <path d="M128.8 184H119.6V193.2H128.8V184Z" fill="black" />
    <path d="M147.2 184H138V193.2H147.2V184Z" fill="black" />
    <path d="M156.4 184H147.2V193.2H156.4V184Z" fill="black" />
    <path d="M165.6 184H156.4V193.2H165.6V184Z" fill="black" />
    <path d="M174.8 184H165.6V193.2H174.8V184Z" fill="black" />
    <path d="M184 184H174.8V193.2H184V184Z" fill="black" />
    <path d="M193.2 184H184V193.2H193.2V184Z" fill="black" />
    <path d="M202.4 184H193.2V193.2H202.4V184Z" fill="black" />
    <path d="M211.6 184H202.4V193.2H211.6V184Z" fill="black" />
    <path d="M220.8 184H211.6V193.2H220.8V184Z" fill="black" />
    <path d="M82.8 193.2H73.6V202.4H82.8V193.2Z" fill="black" />
    <path d="M92 193.2H82.8V202.4H92V193.2Z" fill="black" />
    <path d="M101.2 193.2H92V202.4H101.2V193.2Z" fill="black" />
    <path d="M110.4 193.2H101.2V202.4H110.4V193.2Z" fill="black" />
    <path d="M128.8 193.2H119.6V202.4H128.8V193.2Z" fill="black" />
    <path d="M165.6 193.2H156.4V202.4H165.6V193.2Z" fill="black" />
    <path d="M174.8 193.2H165.6V202.4H174.8V193.2Z" fill="black" />
    <path d="M193.2 193.2H184V202.4H193.2V193.2Z" fill="black" />
    <path d="M220.8 193.2H211.6V202.4H220.8V193.2Z" fill="black" />
    <path d="M230 193.2H220.8V202.4H230V193.2Z" fill="black" />
    <path d="M82.8 202.4H73.6V211.6H82.8V202.4Z" fill="black" />
    <path d="M92 202.4H82.8V211.6H92V202.4Z" fill="black" />
    <path d="M128.8 202.4H119.6V211.6H128.8V202.4Z" fill="black" />
    <path d="M138 202.4H128.8V211.6H138V202.4Z" fill="black" />
    <path d="M184 202.4H174.8V211.6H184V202.4Z" fill="black" />
    <path d="M230 202.4H220.8V211.6H230V202.4Z" fill="black" />
    <path d="M82.8 211.6H73.6V220.8H82.8V211.6Z" fill="black" />
    <path d="M138 211.6H128.8V220.8H138V211.6Z" fill="black" />
    <path d="M147.2 211.6H138V220.8H147.2V211.6Z" fill="black" />
    <path d="M156.4 211.6H147.2V220.8H156.4V211.6Z" fill="black" />
    <path d="M174.8 211.6H165.6V220.8H174.8V211.6Z" fill="black" />
    <path d="M193.2 211.6H184V220.8H193.2V211.6Z" fill="black" />
    <path d="M230 211.6H220.8V220.8H230V211.6Z" fill="black" />
    <path d="M82.8 220.8H73.6V230H82.8V220.8Z" fill="black" />
    <path d="M92 220.8H82.8V230H92V220.8Z" fill="black" />
    <path d="M110.4 220.8H101.2V230H110.4V220.8Z" fill="black" />
    <path d="M119.6 220.8H110.4V230H119.6V220.8Z" fill="black" />
    <path d="M138 220.8H128.8V230H138V220.8Z" fill="black" />
    <path d="M165.6 220.8H156.4V230H165.6V220.8Z" fill="black" />
    <path d="M174.8 220.8H165.6V230H174.8V220.8Z" fill="black" />
    <path d="M184 220.8H174.8V230H184V220.8Z" fill="black" />
    <path d="M193.2 220.8H184V230H193.2V220.8Z" fill="black" />
    <path d="M202.4 220.8H193.2V230H202.4V220.8Z" fill="black" />
    <path d="M211.6 220.8H202.4V230H211.6V220.8Z" fill="black" />
    <path d="M220.8 220.8H211.6V230H220.8V220.8Z" fill="black" />
    <path d="M230 220.8H220.8V230H230V220.8Z" fill="black" />
    <path
      d="M0 0V64.4018H64.4018V0H0ZM55.202 55.202H9.1998V9.1998H55.202V55.202Z"
      fill="black"
    />
    <path d="M46 18.3999H18.4V45.9999H46V18.3999Z" fill="black" />
    <path
      d="M0 165.6V230.002H64.4018V165.6H0ZM55.202 220.802H9.1998V174.8H55.202V220.802Z"
      fill="black"
    />
    <path d="M46 184H18.4V211.6H46V184Z" fill="black" />
    <path
      d="M165.6 0V64.4018H230.002V0H165.6ZM220.802 55.202H174.8V9.1998H220.802V55.202Z"
      fill="black"
    />
    <path d="M211.6 18.3999H184V45.9999H211.6V18.3999Z" fill="black" />
  </g>
  <defs>
    <clipPath id="clip0_66_15">
      <rect width="230" height="230" fill="white" />
    </clipPath>
  </defs>
</svg>
