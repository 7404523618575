import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PasswordPanelComponent } from './password-panel/password-panel.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PanelIndexComponent } from './panel-index/panel-index.component';
import { VisualizePanelComponent } from './components/visualize-panel/visualize-panel.component';
import { ColorSelectComponent } from './components/color-select/color-select.component';
import { ConcentLogoSvgComponent } from './components/concent-logo-svg/concent-logo-svg.component';
import { LoadingInterceptor } from './app.interceptor.module';
import { LoaderComponent } from './components/loader/loader.component';
import { QrCodeSvgComponent } from './components/qr-code-svg/qr-code-svg.component';
import { SelectBeepComponent } from './components/select-beep/select-beep.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorizationModalComponent } from './components/authorization-modal/authorization-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    PasswordPanelComponent,
    PanelIndexComponent,
    VisualizePanelComponent,
    ColorSelectComponent,
    ConcentLogoSvgComponent,
    LoaderComponent,
    QrCodeSvgComponent,
    SelectBeepComponent,
    AuthorizationModalComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
